import React from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { PortalDriverIconProps } from "./PortalDriverIconType";
import { IconVehicle } from "icons";

export default function PortalDriverIcon(
  props: PortalDriverIconProps
): JSX.Element {
  const { t } = useTranslation(["portal"]);

  return (
    <div
      data-testid="driver-location-icon"
      className="w-12 h-12 tablet:w-16 tablet:h-16 flex items-center justify-center relative -translate-x-1/2 -translate-y-1/2 rounded-full"
    >
      <div className="w-full h-full bg-wise-ocean bg-opacity-25 rounded-full absolute inset-0 animate-pulse z-10" />
      <div
        className="w-6 h-6 tablet:w-9 tablet:h-9 flex items-center justify-center bg-wise-ocean rounded-full border-2 border-solid border-white z-20"
        data-for="assignment-driver-icon"
        data-tip={t(`portal:map.tooltip.driver.${props.assignmentStatus}`)}
      >
        <IconVehicle className="w-4 h-4 tablet:w-5 tablet:h-5 text-white" />
      </div>
      <ReactTooltip
        className="whitespace-nowrap rounded-lg shadow-lg"
        place="top"
        type="light"
        role="tooltip"
        effect="solid"
        id="assignment-driver-icon"
      />
    </div>
  );
}
