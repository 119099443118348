import React from "react";
import { currentUserLocale, toMeridiemTime } from "lib/utils/time";
import { IAssignmentTimeWindowType } from "./AssignmentTimeWindowType";

export default function AssignmentTimeWindow(
  props: IAssignmentTimeWindowType
): JSX.Element {
  const { startTime, endTime, customLogoUrl } = props;

  return (
    <div className="flex flex-col flex-1 justify-center items-center divide-y divide-gray-300 laptop:border-r border-solid border-gray-300">
      <div className="flex-1 w-full flex justify-center items-center">
        <span className="text-xl" data-testid="start-time">
          {toMeridiemTime(startTime, currentUserLocale)}
        </span>
      </div>
      <div className="flex-1 w-full flex justify-center items-center">
        <span className="text-xl" data-testid="end-time">
          {toMeridiemTime(endTime, currentUserLocale)}
        </span>
      </div>
      {!!customLogoUrl && (
        <div className="flex-1 w-full flex justify-center items-center">
          <img
            className="max-h-16 w-auto py-1"
            src={`images/customer/custom/${customLogoUrl}`}
            alt="custom customer logo"
          />
        </div>
      )}
    </div>
  );
}
