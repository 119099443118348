import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Header, ErrorPage } from "components";
import { IconResubscribe, IconUnsubscribe } from "icons";
import {
  unsubscribeCustomer,
  resubscribeCustomer,
} from "lib/api/subscriptions";
import { useSearchParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import i18n from "i18n";

export const unsubscribeHeading = i18n.t("unsubscribe:unsubscribeHeading");
export const unsubscribeMessage = i18n.t("unsubscribe:unsubscribeMessage");
export const resubscribeHeading = i18n.t("unsubscribe:resubscribeHeading");
export const resubscribeMessage = i18n.t("unsubscribe:resubscribeMessage");
export const resubscribeButtonText = i18n.t(
  "unsubscribe:resubscribeButtonText"
);
export const error = i18n.t("error:unsubscribeError");

export default function Unsubscribe(): JSX.Element {
  const [searchParams] = useSearchParams({
    email: "",
  });
  const [isError, setIsError] = useState(false);
  const [isButtonHidden, setIsButtonHidden] = useState(false);
  const [subscriptionHeading, setSubscriptionHeading] =
    useState(unsubscribeHeading);
  const [subscriptionMessage, setSubscriptionMessage] =
    useState(unsubscribeMessage);

  const smallScreenClassNames = "small:pl-4 small:pr-1";
  const mobileClassNames = "mobile:pl-9 mobile:pr-4";
  const phabletClassNames = "phablet:pl-16 phablet:pr-16";
  const laptopClassNames = "laptop:pl-16 laptop:pr-16";
  const desktopClassNames = "desktop:pl-16 desktop:pr-16";

  const classNames = clsx(
    "py-12 flex flex-col gap-y-9 max-w-[700px]",
    smallScreenClassNames,
    mobileClassNames,
    phabletClassNames,
    laptopClassNames,
    desktopClassNames
  );

  const handleResubscribeCustomer = async (): Promise<
    AxiosResponse<any, any> | undefined
  > => {
    const email = searchParams.get("email");
    if (!email?.length) return;

    try {
      await resubscribeCustomer(email);

      setIsButtonHidden(true);
      setSubscriptionHeading(resubscribeHeading);
      setSubscriptionMessage(resubscribeMessage);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const email = searchParams.get("email");
    if (!email?.length) return;

    (async () => {
      try {
        await unsubscribeCustomer(email);
      } catch (e) {
        setIsError(true);
      }
    })();
  }, []);

  if (isError) {
    return <ErrorPage error={error} />;
  }

  return (
    <div className="bg-wise-galaxy-50 h-full">
      <Header data-testid="header" />
      <div className={classNames} data-testid="unsubscribe-container">
        {isButtonHidden ? (
          <IconResubscribe data-testid="resubscribe-icon" />
        ) : (
          <IconUnsubscribe data-testid="unsubscribe-icon" />
        )}
        <div className="flex flex-col gap-y-6">
          <div
            className="text-4xl font-medium"
            data-testid="unsubscribe-heading"
          >
            {subscriptionHeading}
          </div>
          <div className="text-base" data-testid="unsubscribe-message">
            {subscriptionMessage}
          </div>
        </div>
        <button
          onClick={handleResubscribeCustomer}
          data-testid="resubscribe-button"
          className={clsx(
            "px-8 py-3 w-fit rounded-md font-medium outline text-white bg-wise-blue hover:bg-wise-ocean-500",
            {
              hidden: isButtonHidden,
            }
          )}
        >
          {resubscribeButtonText}
        </button>
      </div>
    </div>
  );
}
