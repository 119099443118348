import React from "react";
import { Map } from "components";
import { AssignmentLiveMapProps } from "./AssignmentLiveMapType";
import { MapOptions } from "google-map-react";

export default function AssignmentLiveMap(
  props: AssignmentLiveMapProps
): JSX.Element {
  const { children, googleApiKey, onGoogleApiLoaded } = props;

  const mapOptions: MapOptions = {
    disableDefaultUI: true,
    mapTypeControl: false,
    streetViewControl: false,
    disableDoubleClickZoom: false,
    scrollwheel: true,
    draggable: true,
    fullscreenControl: false,
    clickableIcons: false,
    zoomControlOptions: {
      position: 8,
    },
  };

  return (
    <div className="h-60 w-auto" data-testid="live-map">
      <Map
        googleApiKey={googleApiKey}
        onGoogleApiLoaded={onGoogleApiLoaded}
        mapOptions={mapOptions}
      >
        {children}
      </Map>
    </div>
  );
}
