import React from "react";
import { useTranslation } from "react-i18next";
import InventoryItem from "./InventoryItem";
import InventoryItemRow from "./InventoryItemRow";
import { IOrderInventoryItem } from "../../types/order";

interface InventoryItemsTableProps {
  inventoryItems: IOrderInventoryItem[];
}

export default function InventoryItemsTable({
  inventoryItems,
}: InventoryItemsTableProps): JSX.Element {
  const { t } = useTranslation("portal");
  return (
    <div
      className="bg-wise-galaxy-50 rounded-lg p-4 flex flex-col gap-y-4"
      data-testid="inventory-items-table"
    >
      <div className="text-base" data-testid="inventory-items-table-header">
        {t("inventoryItemsTable.tableHeader")}
      </div>
      <div
        className="flex flex-col gap-y-1"
        data-testid="inventory-items-columns"
      >
        <InventoryItemRow>
          <div data-testid="column-item-name">
            {t("inventoryItemsTable.columnNames.itemName")}
          </div>
          <div data-testid="column-quantity">
            {t("inventoryItemsTable.columnNames.actualExpectedQuantity")}
          </div>
        </InventoryItemRow>
        <hr className="bg-wise-galaxy-200 h-px" data-testid="horizontal-line" />
      </div>
      <div
        className="max-h-36 overflow-scroll flex flex-col gap-y-2"
        data-testid="inventory-items"
      >
        {inventoryItems.map((inventoryItem) => (
          <InventoryItem key={inventoryItem.barcodeId} {...inventoryItem} />
        ))}
      </div>
    </div>
  );
}
