import React, { createElement, useRef } from "react";
import { customerIcons } from "icons";
import ReactTooltip from "react-tooltip";
import { AssignmentCustomerIconProps } from "./AssignmentCustomerIconType";

type CustomerIconsType = typeof customerIcons;

export default function AssignmentCustomerIcon({
  iconName,
  customerName,
  onClick,
}: AssignmentCustomerIconProps): JSX.Element {
  const customerIcon = iconName as keyof CustomerIconsType;

  const ref = useRef<HTMLDivElement>(null);

  const renderIconElement = createElement(customerIcons[customerIcon]);

  return (
    <div className="absolute -translate-x-1/2 -translate-y-1/2 cursor-pointer">
      <div
        className="w-12 h-12 overflow-hidden z-50"
        onClick={onClick}
        ref={ref}
        data-testid="customer-icon"
        data-for="assignment-customer-icon"
        data-tip={customerName}
      >
        <ReactTooltip
          className="whitespace-nowrap"
          type="light"
          effect="solid"
          place="top"
          id="assignment-customer-icon"
        />
        {renderIconElement}
      </div>
    </div>
  );
}
