import React, { useEffect, useState } from "react";

import { LegacyPrimaryLayout } from "layouts";
import {
  AssignmentCustomerIcon,
  AssignmentDriverIcon,
  AssignmentLiveMap,
  AssignmentStatus,
  AssignmentTimeWindow,
  ErrorPage,
} from "components";
import { currentUserLocale, toMeridiemTime } from "lib/utils/time";
import { fetchOrder } from "lib/api/orders";
import { useSearchParams } from "react-router-dom";
import { IOrder } from "types/order";
import { IconRefresh } from "icons";
import { buildAssignmentStatus } from "lib/utils";
import { ICoordinates } from "types/common";
import { config } from "lib";
import i18n from "i18n";

const DELIVERY_PICKUP_BY_LYFT = "Your Lyft service technician ";
const TIME_PROJECTION_LYFT = "is projected to arrive between between ";

const LONG_REFRESH_MESSAGE_PRIMARY = i18n.t(
  "assignment:longRefreshMessagePrimary"
);
const LONG_REFRESH_MESSAGE_SECONDARY = i18n.t(
  "assignment:longRefreshMessageSecondary"
);
const SHORT_REFRESH_MESSAGE = i18n.t("assignment:shortRefreshMessage");

export default function Assignment(): JSX.Element {
  const [searchParams] = useSearchParams({
    token: "",
  });

  const reloadPage = (): void => location.reload();

  const [isLoading, setIsLoading] = useState(false);
  const [assignmentData, setAssignmentData] = useState<IOrder | null>(null);

  const [customLogoUrl, setCustomLogoUrl] = useState("");
  const [currentCustomer, setCurrentCustomer] = useState("");
  const [isCustomPortal, setIsCustomPortal] = useState(false);
  const [mapInstance, setMapInstance] = useState(null);
  const [googleMapsApi, setGoogleMapsApi] = useState(null);
  const [showLiveMap, setShowLiveMap] = useState(false);

  useEffect(() => {
    const token = searchParams.get("token");
    if (!token?.length) return;

    const fetchData = async (): Promise<void> => {
      try {
        setIsLoading(true);
        const assignmentDataResponse = await fetchOrder(token);
        setIsLoading(false);

        setAssignmentData(assignmentDataResponse.data?.data);

        const displayCustomPortal =
          assignmentDataResponse.data?.data?.displayCustomPortal;

        const customPortalLogoUrl =
          assignmentDataResponse.data?.data?.customPortalLogoUrl;

        if (
          displayCustomPortal &&
          !["", "false", undefined].includes(customPortalLogoUrl)
        ) {
          setCustomLogoUrl(
            assignmentDataResponse.data.data.customPortalLogoUrl
          );
        }

        if (
          config.customer.clientIds.lyft.includes(
            assignmentDataResponse?.data?.data?.clientId
          )
        ) {
          setCurrentCustomer(config.customer.CUSTOMIZED_CLIENTS.lyft);
        }

        const driverLocation = assignmentDataResponse.data.data.driver.location;

        setShowLiveMap(Boolean(driverLocation));
        setIsCustomPortal(
          Boolean(assignmentDataResponse?.data?.data.displayCustomPortal)
        );
      } catch (err) {
        setIsLoading(false);

        setAssignmentData(null);
        setShowLiveMap(false);
      }
    };

    fetchData();
  }, []);

  const handleZoomToLocation = (location: ICoordinates, map: any): void => {
    map.setCenter(location);
  };

  const handleLoadTrafficLayer = (map: any, maps: any): void => {
    const trafficLayer = new maps.TrafficLayer();
    trafficLayer.setOptions({
      autoRefresh: true,
    });
    trafficLayer.setMap(map);
  };

  const handlePanToBounds = (
    map: any,
    maps: any,
    driverCoordinates: ICoordinates,
    customerCoordinates: ICoordinates
  ): void => {
    const bounds = new maps.LatLngBounds();
    bounds.extend({
      lat: driverCoordinates.lat,
      lng: driverCoordinates.lng,
    });
    bounds.extend({
      lat: customerCoordinates.lat,
      lng: customerCoordinates.lng,
    });
    map.fitBounds(bounds, { bottom: 0, left: 0, top: 0, right: 0 });
  };

  const handleLoadGoogleApi = ({
    maps,
    map,
  }: {
    maps: any;
    map: any;
  }): void => {
    setMapInstance(map);
    setGoogleMapsApi(maps);
  };

  useEffect(() => {
    if (!mapInstance || !googleMapsApi) return;
    handleLoadTrafficLayer(mapInstance, googleMapsApi);

    if (customer.location && driver.location) {
      handlePanToBounds(
        mapInstance,
        googleMapsApi,
        driver.location,
        customer.location
      );
    }
  }, [mapInstance, googleMapsApi]);

  if (isLoading) {
    return <div data-testid="page-loader">Loading...</div>;
  }

  const error = "Uh-oh. This page does not exist.";

  if (!assignmentData) {
    return <ErrorPage error={error} />;
  }

  const { name, customer, driver, status, delay, type, startTime, endTime } =
    assignmentData;

  const googleApiKey =
    process.env.NODE_ENV === "development" &&
    process.env.REACT_APP_GOOGLE_API_KEY
      ? process.env.REACT_APP_GOOGLE_API_KEY
      : assignmentData.google;

  const driverFullName = `${driver.firstname} ${driver.lastname}`;

  const assignmentTypeToDisplay =
    currentCustomer === config.customer.CUSTOMIZED_CLIENTS.lyft
      ? DELIVERY_PICKUP_BY_LYFT
      : `Your ${type} by `;

  const assignmentArrivalTimeToDisplay =
    currentCustomer === config.customer.CUSTOMIZED_CLIENTS.lyft
      ? TIME_PROJECTION_LYFT
      : `has a currently projected ${type} time between `;

  return (
    <LegacyPrimaryLayout>
      <div className="max-w-4xl w-full h-full mx-auto py-8 px-4">
        <section className="mb-10 flex flex-col gap-4 justify-center items-center">
          <h2 className="text-h2 leading-snug text-center">{name}</h2>
          <div className="flex flex-col justify-center">
            <p className="text-xl text-center">{customer.addressLine1}</p>
            <p className="text-xl text-center">{customer.addressLine2}</p>
            <p className="text-xl text-center">{`${customer.city}, ${customer.state} ${customer.zipcode}`}</p>
          </div>
          <h2 className="text-h2 leading-snug text-center">{customer.name}</h2>
        </section>
        <section className="mb-10 flex flex-col items-center laptop:flex-row w-full">
          <div className="h-60 w-full max-w-md flex border-t border-l border-b border-r laptop:border-r-0 border-solid border-gray-300 divide-x divide-gray-300">
            <AssignmentStatus
              driver={driver}
              delayCode={delay}
              statusCode={status}
            />
            <AssignmentTimeWindow
              startTime={startTime}
              endTime={endTime}
              customLogoUrl={customLogoUrl}
            />
          </div>
          {showLiveMap && (
            <div className="h-60 w-full max-w-md border-t border-l border-b laptop:border-b-0 border-solid bg-gray-200 relative overflow-hidden">
              <AssignmentLiveMap
                googleApiKey={googleApiKey}
                onGoogleApiLoaded={handleLoadGoogleApi}
              >
                <AssignmentDriverIcon
                  iconName={buildAssignmentStatus(status, delay).iconKey}
                  lat={driver.location.lat}
                  lng={driver.location.lng}
                  driverName={driverFullName}
                  onClick={() => {
                    handleZoomToLocation(driver.location, mapInstance);
                  }}
                />
                <AssignmentCustomerIcon
                  iconName={"inProgress"}
                  lat={customer.location.lat}
                  lng={customer.location.lng}
                  customerName={customer.name}
                  onClick={() => {
                    handleZoomToLocation(customer.location, mapInstance);
                  }}
                />
              </AssignmentLiveMap>
            </div>
          )}
        </section>
        <section className="pb-10 w-full gap-4">
          <div>
            <p className="text-xl text-center text-mid-gray">
              {assignmentTypeToDisplay}{" "}
              <b className="text-dark-gray">{driverFullName}</b>
            </p>
            <p className="text-xl text-center text-mid-gray">
              {assignmentArrivalTimeToDisplay}
              <b className="text-dark-gray">
                {toMeridiemTime(startTime, currentUserLocale)}
              </b>{" "}
              and{" "}
              <b className="text-dark-gray">
                {toMeridiemTime(endTime, currentUserLocale)}
              </b>
            </p>
          </div>
          <button
            type="button"
            className="mx-auto w-10 h-10 flex items-center justify-center rounded-md hover:bg-gray-200"
            onClick={reloadPage}
          >
            <IconRefresh className="h-6 w-6 text-black" />
          </button>
          {isCustomPortal ? (
            <p className="text-sm text-center">{SHORT_REFRESH_MESSAGE}</p>
          ) : (
            <div className="flex flex-col">
              <p className="text-sm text-center">
                {LONG_REFRESH_MESSAGE_PRIMARY}
              </p>
              <p className="text-sm text-center">
                {LONG_REFRESH_MESSAGE_SECONDARY}
              </p>
            </div>
          )}
        </section>
      </div>
    </LegacyPrimaryLayout>
  );
}
