import React from "react";

export default function Header(): JSX.Element {
  return (
    <div
      className="h-12 w-full flex flex-shrink-0 items-center bg-wise-blue"
      data-testid="header"
    >
      <img
        src={"images/wise/logo-white.png"}
        alt="wise systems logo - white"
        className="h-8 w-16"
      />
    </div>
  );
}
