import React from "react";
import { useTranslation } from "react-i18next";
import {
  formatLocaleString,
  formatLocaleTimeString,
  currentUserLocale,
} from "lib/utils/time";
import { PortalSidePanelHeaderProps } from "./PortalSidePanelHeaderType";
import { AssignmentStatusLabel } from "components";

export default function PortalSidePanelHeader(
  props: PortalSidePanelHeaderProps
): JSX.Element {
  const {
    assignmentType,
    assignmentStatus,
    startTime,
    endTime,
    arrivedAt,
    completedAt,
    statusCode,
    delayCode,
  } = props;

  const { t } = useTranslation("portal");

  const formattedStartTime = formatLocaleTimeString(
    startTime,
    currentUserLocale
  );
  const formattedEndTime = formatLocaleTimeString(endTime, currentUserLocale);
  const formattedArrivedAt = formatLocaleTimeString(
    arrivedAt,
    currentUserLocale
  );
  const formattedCompletedAt = formatLocaleString(
    completedAt,
    currentUserLocale
  );

  return (
    <div className="flex flex-col gap-2">
      <h4
        data-testid="portal-side-panel-subHeading"
        className="text-base text-wise-galaxy-800"
      >
        {t(`portal:order.status.${assignmentType}.${assignmentStatus}`)}
      </h4>
      <h2
        data-testid="portal-side-panel-heading"
        className="text-2xl text-wise-galaxy-800 font-bold"
      >
        {t(`portal:order.eta.${assignmentType}.${assignmentStatus}`, {
          startTime: formattedStartTime,
          endTime: formattedEndTime,
          arrivedAt: formattedArrivedAt,
          completedAt: formattedCompletedAt,
        })}
      </h2>
      <div className="flex">
        <AssignmentStatusLabel statusCode={statusCode} delayCode={delayCode} />
      </div>
    </div>
  );
}
