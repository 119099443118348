interface AssignmentStatusType {
  colorClass: string;
  statusText: string;
  iconKey: string;
}

const buildAssignmentStatus = (
  statusCode: number,
  delayCode?: number | string
): AssignmentStatusType => {
  let assignmentStatus: AssignmentStatusType = {
    colorClass: "wise-green",
    statusText: "early",
    iconKey: "onTime",
  };

  if (statusCode === 0) {
    if (delayCode === -1) {
      assignmentStatus = {
        colorClass: "wise-green",
        statusText: "early",
        iconKey: "onTime",
      };
    } else if (delayCode === 0) {
      assignmentStatus = {
        colorClass: "wise-green",
        statusText: "on time",
        iconKey: "onTime",
      };
    } else if (delayCode === 1) {
      assignmentStatus = {
        colorClass: "wise-yellow",
        statusText: "at risk",
        iconKey: "atRisk",
      };
    } else if (delayCode === 2) {
      assignmentStatus = {
        colorClass: "wise-pink",
        statusText: "late",
        iconKey: "late",
      };
    }
  } else if (statusCode === 1) {
    assignmentStatus = {
      colorClass: "wise-blue",
      statusText: "in progress",
      iconKey: "inProgress",
    };
  } else if (statusCode === 2) {
    if (delayCode === -1) {
      assignmentStatus = {
        statusText: "early",
        colorClass: "wise-green",
        iconKey: "onTime",
      };
    } else if (delayCode === 2) {
      assignmentStatus = {
        colorClass: "wise-pink",
        statusText: "late",
        iconKey: "late",
      };
    } else {
      assignmentStatus = {
        statusText: "complete",
        colorClass: "wise-green",
        iconKey: "onTime",
      };
    }
  } else if (statusCode === 3) {
    assignmentStatus = {
      colorClass: "wise-orange",
      statusText: "cancelled",
      iconKey: "inactive",
    };
  }

  return assignmentStatus;
};

export default buildAssignmentStatus;
