import React from "react";

interface PortalLayoutProps {
  children: React.ReactNode;
}

export default function PortalLayout({
  children,
}: PortalLayoutProps): JSX.Element {
  return (
    <div className="w-full h-full flex" data-testid="primary-layout">
      {children}
    </div>
  );
}
