import React, { createElement } from "react";
import { statusIcons } from "icons";
import clsx from "clsx";

interface StatusProps {
  statusCode: number;
  statusText: string;
  bgColorClass: string;
  iconColorClass: string;
  textColorClass: string;
}

type StatusIconsType = typeof statusIcons;

export default function Status({
  statusCode,
  statusText,
  bgColorClass,
  iconColorClass,
  textColorClass,
}: StatusProps): JSX.Element {
  const statusIcon = statusCode as keyof StatusIconsType;
  const renderIconElement = createElement(statusIcons[statusIcon]);

  return (
    <div className="h-6 w-6" data-testid="status">
      <div
        className={clsx(
          `w-full h-full rounded-full flex items-center justify-center relative`,
          bgColorClass,
          iconColorClass
        )}
        data-testid="status-icon"
      >
        {renderIconElement}
        <div
          className={clsx(
            "absolute top-7 hidden mobile:flex whitespace-nowrap font-medium text-sm text-center",
            textColorClass
          )}
          data-testid="status-text"
        >
          {statusText}
        </div>
      </div>
    </div>
  );
}
