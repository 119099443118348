import React, { createElement, useRef } from "react";
import { driverIcons } from "icons";
import ReactTooltip from "react-tooltip";
import { AssignmentDriverIconProps } from "./AssignmentDriverIconType";

type DriverIconsType = typeof driverIcons;

export default function AssignmentDriverIcon({
  iconName,
  driverName,
  onClick,
}: AssignmentDriverIconProps): JSX.Element {
  const driverIcon = iconName as keyof DriverIconsType;

  const ref = useRef<HTMLDivElement>(null);

  const driverNamesArray = driverName.split(" ");
  const driverInitials = `${driverNamesArray[0][0]}${driverNamesArray[1][0]}`;

  const renderIconElement = createElement(driverIcons[driverIcon]);

  return (
    <div className="absolute -translate-x-1/2 -translate-y-1/2 cursor-pointer">
      <div
        className="w-12 h-12 relative overflow-hidden z-50"
        onClick={onClick}
        ref={ref}
        data-testid="driver-icon"
        data-for="assignment-driver-icon"
        data-tip={driverName}
      >
        <ReactTooltip
          className="whitespace-nowrap"
          type="light"
          effect="solid"
          place="top"
          id="assignment-driver-icon"
        />
        <div className="absolute w-full h-full flex items-center justify-center">
          <span className="text-sm text-white font-semibold">
            {driverInitials}
          </span>
        </div>
        {renderIconElement}
      </div>
    </div>
  );
}
