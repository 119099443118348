import { axiosInstance as axios } from "lib/api/axiosInstance";
import { AxiosResponse } from "axios";

export const unsubscribeCustomer = async (
  customerEmail: string | null
): Promise<AxiosResponse<any>> => {
  if (!customerEmail) {
    return await Promise.reject(new Error("Missing customer email!"));
  }
  return await axios.patch(
    `/customers?action=unsubscribe&email=${customerEmail}`,
    null,
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );
};

export const resubscribeCustomer = async (
  customerEmail: string | null
): Promise<AxiosResponse<any>> => {
  if (!customerEmail) {
    return await Promise.reject(new Error("Missing customer email!"));
  }
  return await axios.patch(
    `/customers?action=subscribe&email=${customerEmail}`,
    null,
    { headers: { "Content-Type": "application/json; charset=utf-8" } }
  );
};
