import React from "react";
import clsx from "clsx";
import { buildAssignmentStatusLabel } from "lib/utils";

interface AssignmentStatusLabelProps {
  statusCode: number;
  delayCode: number;
}

export default function AssignmentStatusLabel({
  statusCode,
  delayCode,
}: AssignmentStatusLabelProps): JSX.Element {
  const { text, bgColorClass, textColorClass, borderColorClass } =
    buildAssignmentStatusLabel(statusCode, delayCode);

  return (
    <div
      data-testid="assignment-status-label"
      className={clsx(
        "flex px-1 rounded border",
        bgColorClass,
        textColorClass,
        borderColorClass
      )}
    >
      <span className="text-base capitalize">{text}</span>
    </div>
  );
}
