export const currentUserLocale = navigator?.languages?.length
  ? navigator.languages[0]
  : navigator.language;

export const toMeridiemTime = (time: string, timeZone: string): string => {
  const timeStringToDate = new Date(time);

  return timeStringToDate.toLocaleString(timeZone, {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};

export const formatLocaleTimeString = (
  time?: string,
  userLocale?: string
): string | undefined => {
  if (!time) return;

  const locale = userLocale ?? "en";

  return new Date(time).toLocaleTimeString(locale, {
    hour: "numeric",
    minute: "numeric",
  });
};

export const formatLocaleString = (
  date?: string,
  userLocale?: string
): string | undefined => {
  if (!date) return;

  const locale = userLocale ?? "en";

  return new Date(date).toLocaleString(locale);
};
