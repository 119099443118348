import React from "react";
import { IconAdd, IconMinus } from "icons";

interface mapType extends google.maps.MapOptions, google.maps.Map {}

interface ZoomControlProps {
  map: mapType;
}
export default function ZoomControl({ map }: ZoomControlProps): JSX.Element {
  const zoomStep = 1;

  function zoomIn(): void {
    if (map.zoom && map.maxZoom) {
      if (map.zoom < map.maxZoom) {
        map.setZoom(map.zoom + zoomStep);
      }
    }
  }

  function zoomOut(): void {
    if (map.zoom && map.minZoom) {
      if (map.zoom > map.minZoom) {
        map.setZoom(map.zoom - zoomStep);
      }
    }
  }

  return (
    <div
      className="z-10 bottom-6 right-6 absolute"
      data-testid="zoom-control-container"
    >
      <div
        className="flex flex-col items-center bg-white rounded-lg shadow-sm"
        data-testid="zoom-control"
      >
        <div className="p-0.5">
          <button
            type="button"
            className="p-1 rounded-lg hover:bg-wise-neptune-100"
            onClick={zoomIn}
            data-testid="zoom-in-button"
          >
            <IconAdd />
          </button>
        </div>
        <hr className="w-2/6 bg-wise-galaxy-100" />
        <div className="p-0.5">
          <button
            type="button"
            className="p-1 rounded-lg hover:bg-wise-neptune-100"
            onClick={zoomOut}
            data-testid="zoom-out-button"
          >
            <IconMinus />
          </button>
        </div>
      </div>
    </div>
  );
}
