import { ErrorPage } from "components";
import React from "react";
import { Routes, Route } from "react-router-dom";
import { Assignment, Unsubscribe, Portal } from "screens";

function App(): JSX.Element {
  const error = "Uh-oh. This page does not exist.";

  return (
    <div className="h-full">
      <Routes>
        <Route path="/" element={<Portal />} />
        <Route path="/legacy" element={<Assignment />} />
        <Route path="/unsubscribe" element={<Unsubscribe />} />
        <Route path="*" element={<ErrorPage error={error} />} />
      </Routes>
    </div>
  );
}

export default App;
