import React from "react";

interface PortalStatusPopupMessageType {
  children: React.ReactNode;
}
export default function PortalStatusPopupMessage({
  children,
}: PortalStatusPopupMessageType): JSX.Element {
  return (
    <div
      className="flex flex-col text-xs tablet:text-base text-wise-galaxy-800 text-center"
      data-testid="portal-status-popup-message"
    >
      {children}
    </div>
  );
}
