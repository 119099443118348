const constants = Object.freeze({
  statusCode: {
    assigned: 0,
    inProgress: 1,
    completed: 2,
    cancelled: 3,
  },
  delayCode: {
    early: -1, // ETA is before time window
    onTime: 0, // ETA is within time window
    atRisk: 1, // ETA + service time is past time window
    late: 2, // ETA is after time window
  },
  media: {
    breakpoints: {
      tablet: 767,
    },
  },
  assignment: {
    status: {
      0: "assigned", // assigned to a driver but has not been started yet
      1: "inProgress", // currently in progress
      2: "completed", //	completed
      3: "cancelled", // assignment was cancelled and can no longer be completed
    },
  },
});

export default constants;
