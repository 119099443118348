import React from "react";
import { Header } from "components";
import logo from "images/wise_blue.png";

export interface ErrorPageProps {
  error: string;
}

export default function ErrorPage(props: ErrorPageProps): JSX.Element {
  return (
    <>
      <Header />
      <div
        data-testid="error-page-container"
        className="flex flex-col items-center justify-center h-screen gap-8"
      >
        <img data-testid="error-page-logo" src={logo} />
        <div
          data-testid="error-page-message"
          className="text-2xl text-center font-normal"
        >
          {props.error}
        </div>
      </div>
    </>
  );
}
