import React from "react";
import i18n from "i18n";
import Status from "./Status";
import { buildStatusTimelineConfig, constants } from "lib/utils";

interface StatusTimelineProps {
  statusCode: number;
}

export default function StatusTimeLine({
  statusCode,
}: StatusTimelineProps): JSX.Element {
  const statuses = {
    inTransit: {
      code: constants.statusCode.assigned,
      text: i18n.t("portal:statusTimeline.inTransit"),
    },
    inProgress: {
      code: constants.statusCode.inProgress,
      text: i18n.t("portal:statusTimeline.inProgress"),
    },
    delivered: {
      code: constants.statusCode.completed,
      text: i18n.t("portal:statusTimeline.delivered"),
    },
  };

  const statusTimelineConfig = buildStatusTimelineConfig(statusCode);

  return (
    <div
      className="w-full h-24 px-8 py-6 bg-wise-galaxy-50 rounded-lg"
      data-testid="status-timeline"
    >
      <div className="h-full w-full flex items-center relative translate-y-0 mobile:-translate-y-3">
        <div
          className="h-0.5 w-full absolute left-0 right-0 bg-wise-galaxy-200 z-10"
          data-testid="status-timeline-bar-fixed"
        />
        <div
          className={`h-0.5 ${statusTimelineConfig.statusBarWidth} absolute left-0 right-0 bg-wise-ocean z-20`}
          data-testid="status-timeline-bar-dynamic"
        />
        <div className="absolute left-0 right-0 z-50">
          <div className="flex justify-between">
            <Status
              statusCode={statuses.inTransit.code}
              statusText={statuses.inTransit.text}
              bgColorClass={statusTimelineConfig.inTransitStatusBgColor}
              iconColorClass={statusTimelineConfig.inTransitStatusIconColor}
              textColorClass={statusTimelineConfig.inTransitStatusTextColor}
            />
            <Status
              statusCode={statuses.inProgress.code}
              statusText={statuses.inProgress.text}
              bgColorClass={statusTimelineConfig.inProgressStatusBgColor}
              iconColorClass={statusTimelineConfig.inProgressStatusIconColor}
              textColorClass={statusTimelineConfig.inProgressStatusTextColor}
            />
            <Status
              statusCode={statuses.delivered.code}
              statusText={statuses.delivered.text}
              bgColorClass={statusTimelineConfig.deliveredStatusBgColor}
              iconColorClass={statusTimelineConfig.deliveredStatusIconColor}
              textColorClass={statusTimelineConfig.deliveredStatusTextColor}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
