import React from "react";
import { IconLocation } from "icons";
import ReactTooltip from "react-tooltip";

interface PortalCustomerIconProps {
  lat: number;
  lng: number;
  customerAddress: string;
}

export default function PortalCustomerIcon(
  props: PortalCustomerIconProps
): JSX.Element {
  return (
    <div
      className="w-12 h-12 tablet:w-16 tablet:h-16 flex items-center justify-center -translate-x-1/2 -translate-y-1/2 relative"
      data-testid="customer-location-icon"
    >
      <div className="w-full h-full bg-wise-ocean bg-opacity-25 rounded-full absolute inset-0 animate-pulse z-10" />
      <div
        className="w-6 h-6 tablet:w-9 tablet:h-9 flex items-center justify-center bg-wise-ocean rounded-full border-2 border-solid border-white z-20"
        data-for="assignment-customer-icon"
        data-tip={props.customerAddress}
      >
        <IconLocation className="w-4 h-4 tablet:w-5 tablet:h-5 text-white" />
      </div>
      <ReactTooltip
        className="w-40 text-center rounded-lg shadow-lg"
        place="top"
        type="light"
        role="tooltip"
        effect="solid"
        id="assignment-customer-icon"
      />
    </div>
  );
}
