import React, { createElement } from "react";
import { useTranslation } from "react-i18next";
import { PortalStatusPopupProps } from "./PortalStatusPopupType";
import { portalStatusPopupIcons } from "icons";
import { constants } from "lib/utils";
import clsx from "clsx";

type PopupIconsType = typeof portalStatusPopupIcons;

export default function PortalStatusPopup(
  props: PortalStatusPopupProps
): JSX.Element {
  const { assignmentType, assignmentStatus, children } = props;

  const { t } = useTranslation(["portal"]);
  const popupIcon = assignmentStatus as keyof PopupIconsType;
  const renderPopupIcon = createElement(portalStatusPopupIcons[popupIcon]);
  const assignmentCancelled =
    assignmentStatus === constants.assignment.status[3];

  const popupClassNames = clsx(
    "flex items-center justify-center rounded-lg border border-gray-50 bg-white shadow-sm -translate-x-1/2 -translate-y-1/2 tablet:w-80 tablet:h-64  tablet:py-12 tablet:px-4",
    {
      "w-45 h-24 py-3 px-3 ": !assignmentCancelled,
      "w-60 h-40": assignmentCancelled,
    }
  );

  const iconClassNames = clsx(
    "tablet:w-15 tablet:h-15 flex items-center justify-center text-wise-ocean",
    {
      "w-5 h-5": !assignmentCancelled,
      "w-9 h-9": assignmentCancelled,
    }
  );
  return (
    <div data-testid="status-popup" className={popupClassNames}>
      <div className="flex flex-col items-center">
        <div className="mb-2 tablet:mb-8">
          <div className={iconClassNames} data-testid="popup-icon">
            {renderPopupIcon}
          </div>
        </div>
        <div className="flex flex-col gap-0 tablet:gap-1">
          <h4
            className="text-xs tablet:text-xl text-wise-galaxy-800 text-center font-bold"
            data-testid="popup-title"
          >
            {t(`portal:map.popup.order.${assignmentType}.${assignmentStatus}`)}
          </h4>
          {children}
        </div>
      </div>
    </div>
  );
}
