import React from "react";
import { buildAssignmentStatus } from "lib/utils";
import { IAssignmentStatusType } from "./AssignmentStatusType";
import clsx from "clsx";

export default function AssignmentStatus(
  props: IAssignmentStatusType
): JSX.Element {
  const { statusCode, delayCode, driver } = props;

  const statusColor = buildAssignmentStatus(statusCode, delayCode).colorClass;

  const mappedColorClasses = {
    "bg-wise-blue": statusColor === "wise-blue",
    "bg-wise-green": statusColor === "wise-green",
    "bg-wise-yellow": statusColor === "wise-yellow",
    "bg-wise-orange": statusColor === "wise-orange",
    "bg-wise-pink": statusColor === "wise-pink",
  };

  return (
    <div className="p-6 h-full flex flex-col gap-3 flex-1 justify-center items-center">
      <div className="flex flex-col items-center gap-1">
        <div
          className={clsx(
            "w-24 h-24 flex items-center justify-center gap-4",
            mappedColorClasses
          )}
        >
          <span
            className="text-6xl text-white"
            data-testid="driver-initials"
          >{`${driver.firstname[0]}${driver.lastname[0]}`}</span>
        </div>
        <h5
          className="text-xl text-center font-bold"
          data-testid="driver-fullname"
        >{`${driver.firstname} ${driver.lastname}`}</h5>
      </div>
      <div
        data-testid="driver-status"
        className={clsx("py-1.5 px-3 rounded-md", mappedColorClasses)}
      >
        <span className="text-sm text-white capitalize">
          {buildAssignmentStatus(statusCode, delayCode).statusText}
        </span>
      </div>
    </div>
  );
}
