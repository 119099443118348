import {
  IconPinAtRisk,
  IconPinCancelled,
  IconPinInProgress,
  IconPinLate,
  IconPinOnTime,
} from "icons";

const customerIcons = {
  cancelled: IconPinCancelled,
  atRisk: IconPinAtRisk,
  inProgress: IconPinInProgress,
  onTime: IconPinOnTime,
  late: IconPinLate,
};

export default customerIcons;
