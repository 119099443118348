import { Header } from "components";
import React from "react";

interface Props {
  children: React.ReactNode;
}

export default function PrimaryLayout({ children }: Props): JSX.Element {
  return (
    <div className="h-full w-full flex flex-col">
      <Header />
      <main className="h-full w-full flex justify-center overflow-y-auto">
        {children}
      </main>
    </div>
  );
}
