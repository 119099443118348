import {
  IconDriverMarkerAtRisk,
  IconDriverMarkerInactive,
  IconDriverMarkerInProgress,
  IconDriverMarkerLate,
  IconDriverMarkerOnTime,
} from "icons";

const driverIcons = {
  atRisk: IconDriverMarkerAtRisk,
  inProgress: IconDriverMarkerInProgress,
  onTime: IconDriverMarkerOnTime,
  inActive: IconDriverMarkerInactive,
  late: IconDriverMarkerLate,
};

export default driverIcons;
