import React, { useEffect, useState } from "react";
import { PrimaryLayout } from "layouts";
import {
  InventoryItemsTable,
  Map,
  PortalSidePanel,
  PortalSidePanelHeader,
  StatusTimeline,
  PortalCustomerIcon,
  PortalDriverIcon,
  PortalStatusPopup,
  PortalStatusPopupMessage,
  ZoomControl,
} from "components";
import { useSearchParams } from "react-router-dom";
import { fetchOrder } from "lib/api/orders";
import { IOrder } from "types/order";
import logo from "images/wise_blue.png";
import { useTranslation } from "react-i18next";
import { constants } from "lib/utils";
import { ICoordinates } from "types/common";
import { GoogleApiLoadedParams } from "google-map-react";
import { useGetWindowDimensions } from "lib/hooks";
import clsx from "clsx";

export default function Portal(): JSX.Element {
  const { width } = useGetWindowDimensions();
  const [isMobile, setIsMobile] = useState(false);

  const { t } = useTranslation(["common", "error"]);

  const [searchParams] = useSearchParams({
    token: "",
  });

  const [googleMapInstance, setGoogleMapInstance] = useState<
    GoogleApiLoadedParams["map"] | null
  >(null);
  const [googleMapsApi, setGoogleMapsApi] = useState<
    GoogleApiLoadedParams["maps"] | null
  >(null);

  const [isLoading, setIsLoading] = useState(false);
  const [assignmentData, setAssignmentData] = useState<IOrder | null>(null);
  const displayInventoryItems = Boolean(assignmentData?.inventoryItems?.length);

  useEffect(() => {
    const token = searchParams.get("token");
    if (!token?.length) return;

    const fetchData = async (): Promise<void> => {
      try {
        setIsLoading(true);
        const assignmentDataResponse = await fetchOrder(token);
        setIsLoading(false);

        setAssignmentData(assignmentDataResponse.data?.data);
      } catch (err) {
        setIsLoading(false);

        setAssignmentData(null);
      }
    };

    fetchData();
  }, []);

  const handleLoadGoogleApi = ({
    map,
    maps,
  }: {
    map: GoogleApiLoadedParams["map"];
    maps: GoogleApiLoadedParams["maps"];
  }): void => {
    setGoogleMapInstance(map);
    setGoogleMapsApi(maps);
  };

  const handleSetMapBounds = (
    map: GoogleApiLoadedParams["map"],
    maps: GoogleApiLoadedParams["maps"],
    driverCoordinates: ICoordinates,
    customerCoordinates: ICoordinates
  ): void => {
    const bounds = new maps.LatLngBounds();
    bounds.extend({
      lat: driverCoordinates.lat,
      lng: driverCoordinates.lng,
    });
    bounds.extend({
      lat: customerCoordinates.lat,
      lng: customerCoordinates.lng,
    });
    map.fitBounds(bounds, { bottom: 0, left: 0, top: 0, right: 0 });
  };

  const handleLoadTrafficLayer = (
    map: GoogleApiLoadedParams["map"],
    maps: GoogleApiLoadedParams["maps"]
  ): void => {
    const trafficLayer = new maps.TrafficLayer();
    trafficLayer.setOptions({
      autoRefresh: true,
    });
    trafficLayer.setMap(map);
  };

  const handleSetMapCenter = (
    map: GoogleApiLoadedParams["map"],
    location: ICoordinates
  ): void => {
    map.setCenter(location);
  };

  useEffect(() => {
    if (width <= constants.media.breakpoints.tablet) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  useEffect(() => {
    if (!googleMapInstance || !googleMapsApi) return;

    handleLoadTrafficLayer(googleMapInstance, googleMapsApi);

    if (!assignmentData?.customer.location) return;

    if (!assignmentData?.driver.location) {
      // center map at customer location when no driver location
      handleSetMapCenter(googleMapInstance, assignmentData?.customer?.location);
      return;
    }

    handleSetMapBounds(
      googleMapInstance,
      googleMapsApi,
      assignmentData?.customer.location,
      assignmentData?.driver.location
    );
  }, [
    googleMapsApi,
    googleMapInstance,
    assignmentData?.customer.location,
    assignmentData?.driver.location,
  ]);

  useEffect(() => {
    if (!googleMapInstance || !assignmentData?.customer?.location) return;

    if (
      assignmentData.status !== constants.statusCode.completed &&
      assignmentData.status !== constants.statusCode.cancelled
    )
      return;

    handleSetMapCenter(googleMapInstance, assignmentData?.customer?.location);
  }, [
    googleMapInstance,
    assignmentData?.status,
    assignmentData?.customer?.location,
  ]);

  // TODO: 01/09/2023 Add loading state that we get from design once design is complete
  if (isLoading) {
    return <div data-testid="page-loader">{t("common:loading")}</div>;
  }

  // TODO: 01/09/2023 update the error handling for this page so its more helpful to customers

  const error = t("error:pageNoLongerExists");
  if (!assignmentData) {
    return (
      <div
        data-testid="error-page-container"
        className="flex flex-col items-center justify-center h-screen gap-8"
      >
        <img data-testid="error-page-logo" src={logo} className="h-8 w-auto" />
        <h1
          data-testid="error-page-message"
          className="text-2xl text-center font-normal"
        >
          {error}
        </h1>
      </div>
    );
  }

  const googleApiKey =
    process.env.NODE_ENV === "development" &&
    process.env.REACT_APP_GOOGLE_API_KEY
      ? process.env.REACT_APP_GOOGLE_API_KEY
      : assignmentData.google;

  const {
    type: assignmentType,
    status,
    startTime,
    endTime,
    arrivedAt,
    completedAt,
    driver,
    customer,
    delay,
  } = assignmentData;
  const { addressLine1, addressLine2, city, state, zipcode } = customer;

  const assignmentStatus = constants.assignment.status[status];

  const assignmentIncomplete = [
    constants.statusCode.assigned,
    constants.statusCode.inProgress,
  ].includes(status);
  const assignmentCompleted = status === constants.statusCode.completed;
  const assignmentCancelled = status === constants.statusCode.cancelled;

  const controllableMapOptions = {
    draggable: true,
    scrollwheel: true,
    disableDoubleClickZoom: false,
  };

  const restrictedMapControlOptions = {
    draggable: false,
    scrollwheel: false,
    keyboardShortcuts: false,
    disableDoubleClickZoom: true,
    center: {
      lat: customer.location.lat,
      lng: customer.location.lng,
    },
  };

  const mapControlOptions = assignmentIncomplete
    ? controllableMapOptions
    : restrictedMapControlOptions;

  const mapClassNamesMobileVersion = clsx(
    "block tablet:hidden aspect-[9/4] relative bg-gray-100",
    {
      "h-full w-full": assignmentCancelled,
      "mb-5": !assignmentCancelled,
    }
  );

  return (
    <PrimaryLayout>
      {!assignmentCancelled && (
        <PortalSidePanel>
          <div className="mb-5">
            <PortalSidePanelHeader
              startTime={startTime}
              endTime={endTime}
              arrivedAt={arrivedAt}
              completedAt={completedAt}
              assignmentType={assignmentType}
              assignmentStatus={assignmentStatus}
              statusCode={status}
              delayCode={delay}
            />
          </div>
          {isMobile && (
            <div
              className={mapClassNamesMobileVersion}
              data-testid="mobile-map-container"
            >
              <Map
                defaultZoom={12}
                mapOptions={{
                  ...mapControlOptions,
                  clickableIcons: false,
                  disableDefaultUI: true,
                  mapTypeControl: false,
                  maxZoom: 20,
                  streetViewControl: false,
                  zoomControl: false,
                  gestureHandling: "cooperative",
                }}
                googleApiKey={googleApiKey}
                onGoogleApiLoaded={handleLoadGoogleApi}
              >
                {assignmentIncomplete && (
                  <PortalCustomerIcon
                    lat={customer.location.lat}
                    lng={customer.location.lng}
                    customerAddress={t("portal:map.tooltip.customer.address", {
                      addressLine1,
                      addressLine2,
                      city,
                      state,
                      zipcode,
                    })}
                  />
                )}
                {assignmentIncomplete && driver.location && (
                  <PortalDriverIcon
                    lat={driver.location.lat}
                    lng={driver.location.lng}
                    assignmentStatus={assignmentStatus}
                  />
                )}
                {assignmentCompleted && (
                  <PortalStatusPopup
                    lat={customer.location.lat}
                    lng={customer.location.lng}
                    assignmentStatus={assignmentStatus}
                    assignmentType={assignmentType}
                  >
                    <PortalStatusPopupMessage>
                      <p data-testid="status-popup-addressLine1">
                        {customer.addressLine1}
                      </p>
                      <p data-testid="status-popup-addressLine2">
                        {customer.addressLine2}
                      </p>
                      <p>
                        <span data-testid="status-popup-city">
                          {`${customer.city}, `}
                        </span>
                        <span data-testid="status-popup-state">
                          {`${customer.state}, `}
                        </span>
                        <span data-testid="status-popup-zipcode">
                          {customer.zipcode}
                        </span>
                      </p>
                    </PortalStatusPopupMessage>
                  </PortalStatusPopup>
                )}
              </Map>
            </div>
          )}
          {!assignmentCancelled && (
            <div className="mb-5">
              <StatusTimeline statusCode={status} />
            </div>
          )}
          {displayInventoryItems && !assignmentCancelled && (
            <InventoryItemsTable
              inventoryItems={assignmentData.inventoryItems}
            />
          )}
        </PortalSidePanel>
      )}
      {!isMobile && (
        <div
          className={clsx("relative bg-gray-100", {
            "hidden tablet:flex h-full tablet:w-full": !isMobile,
            "flex h-full w-full": isMobile && assignmentCancelled,
          })}
          data-testid="desktop-map-container"
        >
          <Map
            mapOptions={{
              ...mapControlOptions,
              clickableIcons: false,
              disableDefaultUI: true,
              mapTypeControl: false,
              maxZoom: 20,
              streetViewControl: false,
              zoomControl: false,
            }}
            googleApiKey={googleApiKey}
            onGoogleApiLoaded={handleLoadGoogleApi}
            overlay={
              googleMapInstance !== null &&
              !isMobile && <ZoomControl map={googleMapInstance} />
            }
          >
            {assignmentIncomplete && (
              <PortalCustomerIcon
                lat={customer.location.lat}
                lng={customer.location.lng}
                customerAddress={t("portal:map.tooltip.customer.address", {
                  addressLine1,
                  addressLine2,
                  city,
                  state,
                  zipcode,
                })}
              />
            )}
            {assignmentIncomplete && driver.location && (
              <PortalDriverIcon
                lat={driver.location.lat}
                lng={driver.location.lng}
                assignmentStatus={assignmentStatus}
              />
            )}
            {assignmentCompleted && (
              <PortalStatusPopup
                lat={customer.location.lat}
                lng={customer.location.lng}
                assignmentStatus={assignmentStatus}
                assignmentType={assignmentType}
              >
                <PortalStatusPopupMessage>
                  <p data-testid="status-popup-addressLine1">
                    {customer.addressLine1}
                  </p>
                  <p data-testid="status-popup-addressLine2">
                    {customer.addressLine2}
                  </p>
                  <p>
                    <span data-testid="status-popup-city">
                      {`${customer.city}, `}
                    </span>
                    <span data-testid="status-popup-state">
                      {`${customer.state}, `}
                    </span>
                    <span data-testid="status-popup-zipcode">
                      {customer.zipcode}
                    </span>
                  </p>
                </PortalStatusPopupMessage>
              </PortalStatusPopup>
            )}
            {assignmentCancelled && (
              <PortalStatusPopup
                lat={customer.location.lat}
                lng={customer.location.lng}
                assignmentStatus={assignmentStatus}
                assignmentType={assignmentType}
              >
                <PortalStatusPopupMessage>
                  <p data-testid="status-popup-addressLine1">
                    {customer.addressLine1}
                  </p>
                  <p data-testid="status-popup-addressLine2">
                    {customer.addressLine2}
                  </p>
                  <p>
                    <span data-testid="status-popup-city">
                      {`${customer.city}, `}
                    </span>
                    <span data-testid="status-popup-state">
                      {`${customer.state}, `}
                    </span>
                    <span data-testid="status-popup-zipcode">
                      {customer.zipcode}
                    </span>
                  </p>
                </PortalStatusPopupMessage>
              </PortalStatusPopup>
            )}
          </Map>
        </div>
      )}
    </PrimaryLayout>
  );
}
