import React from "react";
import clsx from "clsx";
import { PortalSidePanelProps } from "./PortalSidePanelType";
import { WiseLogo } from "../../icons";

export default function PortalSidePanel({
  children,
}: PortalSidePanelProps): JSX.Element {
  const defaultClasses = clsx(
    "pt-11 px-4 w-full h-full overflow-x-hidden overflow-y-auto bg-white"
  );
  const phabletClasses = "phablet:px-6";
  const tabletClasses = "tablet:px-4 tablet:max-w-sm";
  const laptopClasses = "laptop:px-6 laptop:max-w-md";
  const desktopClasses = "desktop:px-10 desktop:max-w-lg";

  return (
    <div
      data-testid="portal-side-panel"
      className={clsx(
        defaultClasses,
        phabletClasses,
        tabletClasses,
        laptopClasses,
        desktopClasses
      )}
    >
      <div className="mb-6">
        <WiseLogo data-testid="wise-logo" />
      </div>
      {children}
    </div>
  );
}
