import React from "react";
import InventoryItemRow from "../InventoryItemRow";
import { useTranslation } from "react-i18next";

interface InventoryItemProps {
  itemName: string;
  barcodeId: string;
  expectedQuantity: string | number;
  actualQuantity: string | number;
}
export default function InventoryItem({
  itemName,
  barcodeId,
  expectedQuantity,
  actualQuantity,
}: InventoryItemProps): JSX.Element {
  const { t } = useTranslation("portal");
  return (
    <InventoryItemRow>
      <div data-testid="inventory-item">
        <div data-testid="inventory-item-name">{itemName}</div>
        <div
          className="text-wise-galaxy-500"
          data-testid="inventory-item-barcode"
        >
          {t("inventoryItemsTable.inventoryItemProps.barcodeId", {
            barcodeId,
          })}
        </div>
      </div>
      <div data-testid="inventory-item-quantity">
        {actualQuantity}/{expectedQuantity}
      </div>
    </InventoryItemRow>
  );
}
