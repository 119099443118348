import { axiosInstance as axios } from "lib/api/axiosInstance";
import { AxiosApiResponse } from "types/api";
import { IOrder } from "types/order";

export const fetchOrder = async (
  customerToken: string
): Promise<AxiosApiResponse<IOrder>> => {
  return await axios.get("/order", {
    params: {
      token: customerToken,
    },
  });
};
