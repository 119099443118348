import React from "react";
import GoogleMapReact, { GoogleApiLoadedParams } from "google-map-react";
import { config } from "lib";

import { MapProps } from "./MapType";

export default function Map(props: MapProps): JSX.Element {
  const {
    children,
    defaultZoom,
    googleApiKey,
    mapOptions,
    overlay,
    onGoogleApiLoaded,
  } = props;

  const googleMapProps = {
    bootstrapURLKeys: {
      key: googleApiKey,
    },
    center: config.mapOptions.default.center,
    defaultZoom: defaultZoom ?? config.mapOptions.default.defaultZoom,
    options: mapOptions,
    onGoogleApiLoaded: (maps: GoogleApiLoadedParams) => onGoogleApiLoaded(maps),
    yesIWantToUseGoogleMapApiInternals: true,
  };

  return (
    <div data-testid="google-map" className="w-full h-full overflow-hidden">
      {overlay}
      <GoogleMapReact {...googleMapProps}>{children}</GoogleMapReact>
    </div>
  );
}
