interface StatusTimelineConfigType {
  statusBarWidth: string;
  inTransitStatusBgColor: string;
  inTransitStatusIconColor: string;
  inTransitStatusTextColor: string;
  inProgressStatusBgColor: string;
  inProgressStatusIconColor: string;
  inProgressStatusTextColor: string;
  deliveredStatusBgColor: string;
  deliveredStatusIconColor: string;
  deliveredStatusTextColor: string;
}

const buildStatusTimelineConfig = (
  statusCode: number
): StatusTimelineConfigType => {
  let statusTimelineConfig: StatusTimelineConfigType = {
    statusBarWidth: "w-0",
    inTransitStatusBgColor: "bg-wise-galaxy-200",
    inTransitStatusIconColor: "text-wise-galaxy-500",
    inTransitStatusTextColor: "text-wise-galaxy-300",
    inProgressStatusBgColor: "bg-wise-galaxy-200",
    inProgressStatusIconColor: "text-wise-galaxy-500",
    inProgressStatusTextColor: "text-wise-galaxy-300",
    deliveredStatusBgColor: "bg-wise-galaxy-200",
    deliveredStatusIconColor: "text-wise-galaxy-500",
    deliveredStatusTextColor: "text-wise-galaxy-300",
  };

  switch (statusCode) {
    case 0:
      statusTimelineConfig = {
        statusBarWidth: "w-0",
        inTransitStatusBgColor: "bg-wise-ocean",
        inTransitStatusIconColor: "text-white",
        inTransitStatusTextColor: "text-wise-galaxy-800",
        inProgressStatusBgColor: "bg-wise-galaxy-200",
        inProgressStatusIconColor: "text-wise-galaxy-500",
        inProgressStatusTextColor: "text-wise-galaxy-300",
        deliveredStatusBgColor: "bg-wise-galaxy-200",
        deliveredStatusIconColor: "text-wise-galaxy-500",
        deliveredStatusTextColor: "text-wise-galaxy-300",
      };
      break;
    case 1:
      statusTimelineConfig = {
        statusBarWidth: "w-1/2",
        inTransitStatusBgColor: "bg-wise-ocean",
        inTransitStatusIconColor: "text-white",
        inTransitStatusTextColor: "text-wise-galaxy-300",
        inProgressStatusBgColor: "bg-wise-ocean",
        inProgressStatusIconColor: "text-white",
        inProgressStatusTextColor: "text-wise-galaxy-800",
        deliveredStatusBgColor: "bg-wise-galaxy-200",
        deliveredStatusIconColor: "text-wise-galaxy-500",
        deliveredStatusTextColor: "text-wise-galaxy-300",
      };
      break;
    case 2:
      statusTimelineConfig = {
        statusBarWidth: "w-full",
        inTransitStatusBgColor: "bg-wise-ocean",
        inTransitStatusIconColor: "text-white",
        inTransitStatusTextColor: "text-wise-galaxy-300",
        inProgressStatusBgColor: "bg-wise-ocean",
        inProgressStatusIconColor: "text-white",
        inProgressStatusTextColor: "text-wise-galaxy-300",
        deliveredStatusBgColor: "bg-wise-ocean",
        deliveredStatusIconColor: "text-white",
        deliveredStatusTextColor: "text-wise-galaxy-800",
      };
      break;
  }

  return statusTimelineConfig;
};
export default buildStatusTimelineConfig;
