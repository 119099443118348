import React from "react";

interface InventoryItemRowProps {
  children: React.ReactNode;
}
export default function InventoryItemRow({
  children,
}: InventoryItemRowProps): JSX.Element {
  return (
    <div className="grid grid-cols-2 text-sm" data-testid="inventory-item-row">
      {children}
    </div>
  );
}
