const config = Object.freeze({
  customer: {
    CUSTOMIZED_CLIENTS: {
      lyft: "lyft",
    },
    clientIds: {
      lyft: [
        "144b16e0-c8d7-11e9-b324-cd2b2503616e", // lyft-sf
        "040329b0-cb27-11e9-833f-9dcaba2e314d", // lyft-SFO
        "5f953d90-cb27-11e9-83ae-d93db37501ee", // lyft-ca (UAT)
        "ccae4d10-a271-11e9-9647-b93981f792ae", // lyft-ca_stage (stage)
        "8ca92100-4478-11ea-9157-399e419eae34", // lyft-PHI
        "d3da4730-467f-11ea-91cc-df6d124d1158", // lyft-IAH
        "21e2c790-4680-11ea-9157-399e419eae34", // lyft-AHL
        "70d2e8d0-4680-11ea-91cc-df6d124d1158", // lyft-LAX
        "d536d480-4680-11ea-91cc-df6d124d1158",
      ], // lyft-PDX
    },
  },
  mapOptions: {
    default: {
      defaultZoom: 13,
      center: {
        lat: Number(
          process.env.REACT_APP_GOOGLE_MAPS_DEFAULT_CENTER_LAT ??
            42.346898373563384
        ),
        lng: Number(
          process.env.REACT_APP_GOOGLE_MAPS_DEFAULT_CENTER_LNG ??
            -71.09670280487947
        ),
      },
    },
  },
});

export default config;
