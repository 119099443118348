import i18n from "i18n";
import { constants } from "lib/utils";

interface AssignmentStatusType {
  text: string;
  textColorClass: string;
  bgColorClass: string;
  borderColorClass: string;
}

const buildAssignmentStatusLabel = (
  statusCode: number,
  delayCode: number
): AssignmentStatusType => {
  switch (statusCode) {
    case constants.statusCode.assigned:
      switch (delayCode) {
        case constants.delayCode.early:
          return {
            text: i18n.t("portal:statusLabel.early"),
            bgColorClass: "bg-wise-earth-100",
            textColorClass: "text-wise-earth",
            borderColorClass: "border-wise-earth",
          };
        case constants.delayCode.onTime:
          return {
            text: i18n.t("portal:statusLabel.onTime"),
            bgColorClass: "bg-wise-earth-100",
            textColorClass: "text-wise-earth",
            borderColorClass: "border-wise-earth",
          };
        case constants.delayCode.atRisk:
          return {
            text: i18n.t("portal:statusLabel.atRisk"),
            bgColorClass: "bg-wise-saturn-100",
            textColorClass: "text-wise-saturn-500",
            borderColorClass: "border-wise-saturn-500",
          };
        case constants.delayCode.late:
          return {
            text: i18n.t("portal:statusLabel.late"),
            bgColorClass: "bg-wise-mars-100",
            textColorClass: "text-wise-mars",
            borderColorClass: "border-wise-mars",
          };
        default:
          return {
            text: i18n.t("portal:statusLabel.invalid"),
            bgColorClass: "bg-wise-galaxy-100",
            textColorClass: "text-wise-galaxy-500",
            borderColorClass: "border-wise-galaxy-500",
          };
      }
    case constants.statusCode.inProgress:
      return {
        text: i18n.t("portal:statusLabel.inProgress"),
        bgColorClass: "bg-wise-neptune-100",
        textColorClass: "text-wise-neptune",
        borderColorClass: "border-wise-neptune",
      };
    case constants.statusCode.completed:
      switch (delayCode) {
        case constants.delayCode.early:
          return {
            text: i18n.t("portal:statusLabel.early"),
            bgColorClass: "bg-wise-earth-100",
            textColorClass: "text-wise-earth",
            borderColorClass: "border-wise-earth",
          };
        case constants.delayCode.atRisk:
        case constants.delayCode.onTime:
          return {
            text: i18n.t("portal:statusLabel.completed"),
            bgColorClass: "bg-wise-earth-100",
            textColorClass: "text-wise-earth",
            borderColorClass: "border-wise-earth",
          };
        case constants.delayCode.late:
          return {
            text: i18n.t("portal:statusLabel.late"),
            bgColorClass: "bg-wise-mars-100",
            textColorClass: "text-wise-mars",
            borderColorClass: "border-wise-mars",
          };
        default:
          return {
            text: i18n.t("portal:statusLabel.invalid"),
            bgColorClass: "bg-wise-galaxy-100",
            textColorClass: "text-wise-galaxy-500",
            borderColorClass: "border-wise-galaxy-500",
          };
      }
    case constants.statusCode.cancelled:
      return {
        text: i18n.t("portal:statusLabel.cancelled"),
        bgColorClass: "bg-wise-venus-100",
        textColorClass: "text-wise-venus-400",
        borderColorClass: "border-wise-venus-400",
      };
    default:
      return {
        text: i18n.t("portal:statusLabel.invalid"),
        bgColorClass: "bg-wise-wise-galaxy-100",
        textColorClass: "text-wise-galaxy-500",
        borderColorClass: "border-wise-galaxy-500",
      };
  }
};

export default buildAssignmentStatusLabel;
